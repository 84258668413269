import { Component, PropSync, Vue } from "vue-property-decorator";

@Component<Header>({
  components: {}
})
export default class Header extends Vue {
  @PropSync("currentPage", { type: String }) syncedCurrentPage!: string;
  private authority = '';

  public created() {
      this.authority = window.localStorage.getItem('teacher_authority') as string;
  }

  public async logout() {
    window.localStorage.clear();
    this.$router.push("/teacher/login");
  }

}