import { Component, PropSync, Vue } from "vue-property-decorator";

@Component<SideNav>({
  components: {}
})
export default class SideNav extends Vue {
  @PropSync("currentPage", { type: String }) syncedCurrentPage!: string;
  private aaid = '';
  private name = '';
  private authority = '';

  public created() {
    this.name = window.localStorage.getItem('admin_name') as string;
    this.aaid = window.localStorage.getItem('admin_aaid') as string;
    this.authority = window.localStorage.getItem('admin_authority') as string;
  }

  //側邊欄選單
  // public closeNav(){
  //   $('#closeNav').animate({left:'-26px'},300);
  //   $('.side-nav').animate({width:'0'},300);
  //   $('.btn-side-open').animate({left:'0'},400);
  // };

  // public openNav(){
  //   $('#closeNav').animate({left:'180px'},300);
  //   $('.side-nav').animate({width:'180px'},300);
  //   $('.btn-side-open').animate({left:'-26px'},400);
  // };

  public logout() {
    window.localStorage.clear();
    this.$router.push("/admin/login");
  }
}
