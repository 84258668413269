import { Component, PropSync, Vue } from "vue-property-decorator";

@Component<Header>({
  components: {}
})
export default class Header extends Vue {
  @PropSync("currentPage", { type: String }) syncedCurrentPage!: string;

  public async logout() {
    window.localStorage.clear();
    this.$router.push("/student/login");
  }
}